//const defaultUrl = "http://localhost:8080"
const defaultUrl = "https://omega-post-416123.ey.r.appspot.com/"

// Basic req functions
const sendReq = (opts: Object, url: string, endpoint: string, errMsgSetter?: Function) => {
    console.log(url+endpoint)
  return fetch(url + endpoint, opts)
    .then(async (res) => {
        console.log(res)
      let temp = await res.json();
      temp.status = res.status;
      return temp;
    })
    .catch((err) => {
      console.log(err);
      if (errMsgSetter) {errMsgSetter("Něco se pokazilo")}
    });
};

export const sendGetReq = async (endpoint: string, errMsgSetter?: Function, url: string = defaultUrl) => {
  const opts = {
    method: "GET",
  };
  return await sendReq(opts, url, endpoint, errMsgSetter);
};

export const sendPostReq = async (endpoint: string, body: {[key: string]: any}, errMsgSetter?: Function, url: string = defaultUrl) => {
  const opts = {
    method: "POST",
    body: JSON.stringify(body),
    headers: {
      "Content-type": "application/json; charset=UTF-8",
    } 
  };
  return await sendReq(opts, url, endpoint, errMsgSetter);
};




// Abstract req functions
export const fetchFile = async (id: string, setter: Function) => {

    let res = await sendGetReq("/inspectorPortal/getFile?id="+id)

    if (res.msg === "err") {
        setter("Dokument s tímto ID neexistuje")
        return
    }

    setter(`<h3><a href="${res.data}" target="_blank" rel="noopener noreferrer" style="text-decoration: underline;">pro soubor klikni sem</a></h3>`);
    return;
}