import { useState } from 'react';
import './App.css';
import { fetchFile } from './dataHandler';

function App() {

  let [inp,setInp] = useState("")
  let [err,setErr] = useState("")

  const onChange = (e: any) => {
    setInp(e.target.value)
  }

  return (
    <div>
      <h1>Portál inspektora Prohlížeče</h1>

        <h2>Dostupné soubory</h2>
        <ul>
          <li><a href="https://static25.smoulasoutez.cz/inspektorProhlizec/theSumplerkTimes.pdf" target="_blank" rel="noopener noreferrer">Novinový článek</a></li>
          <li><a href="https://static25.smoulasoutez.cz/inspektorProhlizec/logbook.pdf" target="_blank" rel="noopener noreferrer">Kniha návštěv vily p. Prikové</a></li>
          <li><a href="https://static.smoulasoutez.cz/season/1/reseni/obrazekDuch.jpeg" target="_blank" rel="noopener noreferrer">Největší dílo p. Prikové - Hrách dřeňový</a></li>
        </ul>

        <h2>Pro získání ostatních souborů prosím zadejte jejich ID:</h2>
        <input onChange={onChange}/>
        <button onClick={() => {fetchFile(inp,setErr)}}>Potvrdit</button>
        <p dangerouslySetInnerHTML={{ __html: err }}></p>

    </div>
  );
}

export default App;
